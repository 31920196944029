let AbstractComponent = require('../../AbstractComponent');
const {
  createElementWithAttribute,
  getDataFromFirebase,
} = require('../../../utils/functions');
const shortid = require('shortid');

const Editor = require("../../../utils/editor")


class AssignmentView extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = elem;
    this.uid = localStorage.getItem('uid');
    this.currentClassId = null;
    this.currentEditAssignmentId = null;
    this.currentEditAssignmentModule = null;
    this.assignmentArray = [];

    this.init();
  }

  init() {
    this.createClassesSelector();
    this.initAssignmentCreateOrEditForm();
    this.initAssignmentList();
  }

  createClassesSelector() {
    const selectorContainer = createElementWithAttribute('div', {
      class: 'classes-selector-container',
    });
    selectorContainer.innerHTML = `<select name="select-class-name" id="assignment-select-class-name" class="form-control">
        <option>No class found, please create a class in Manage menu</option>
        </select>`;
    this.parent.appendChild(selectorContainer);

    //Update Class list
    let that = this;

    const selectClassContainerAssignment = document.getElementById(
      'assignment-select-class-name'
    );

    //Fetch Class list from firebase
    getDataFromFirebase('/classes', this.uid).then((result) => {
      if (result.status === 'OK') {
        const classInfo = result.data;

        //Output HTML element for select list
        let selectOptions = '';

        for (let [key, value] of Object.entries(classInfo)) {
          let classId = key;
          let className = value.name;
          selectOptions += `<option value=${classId} >${className}</option>`;
        }

        //Init currentClassId equal the first class id
        that.currentClassId = Object.keys(classInfo)[0];
        that.getAssignmentList(that.currentClassId);

        selectClassContainerAssignment.innerHTML = selectOptions;

        selectClassContainerAssignment.addEventListener('change', (e) => {
          that.currentClassId = selectClassContainerAssignment.value;
          that.getAssignmentList(that.currentClassId);

          document.getElementById('assignment-form-error-message').innerText =
            '';
        });
      }
    });
  }

  initAssignmentList() {
    const assignmentListContainer = createElementWithAttribute('div', {
      class: 'assignment-container',
    });

    const assignmentListContainerHeader = createElementWithAttribute('div', {
      class: 'assignment-container-header',
    });

    assignmentListContainer.appendChild(assignmentListContainerHeader);

    const assignmentListTitle = createElementWithAttribute('h3', {
      class: 'assignment-list-title',
    });
    assignmentListTitle.innerText = 'Assignment List';
    assignmentListContainerHeader.appendChild(assignmentListTitle);

    const assignmentList = createElementWithAttribute('ul', {
      id: 'assignment-list',
      class: 'list-group',
    });

    assignmentListContainer.appendChild(assignmentList);

    //Add new assignment button
    const createAssignmentBtn = createElementWithAttribute('button', {
      id: 'create-assignment-btn',
      title: 'Add new assignment',
    });

    createAssignmentBtn.innerText = '+';
    //Click to open create form
    createAssignmentBtn.addEventListener('click', (e) => {
      document.querySelector('.assignment-form-title').innerText =
        'Create New Assignment';
      const assignmentForm = document.querySelector(
        '.assignment-form-container'
      );
      assignmentForm.classList.remove('hide');
      document
        .getElementById('assignment-form-error-message')
        .classList.remove('show-error-message');

      document.getElementById('form-input-assignment-name').value = '';
      // document.getElementById('form-input-assignment-description').value = '';
      document
        .getElementById('assignment-form-create-btn')
        .classList.remove('hide');
    });

    //Assignment action container
    const actionContainer = createElementWithAttribute('div', {
      class: 'action-container',
      id: 'assignment-actions',
    });

    // actionContainer.appendChild(viewProgressBtn);
    actionContainer.appendChild(createAssignmentBtn);

    assignmentListContainerHeader.appendChild(actionContainer);

    this.parent.appendChild(assignmentListContainer);
  }

  initAssignmentCreateOrEditForm() {
    const assignmentFormContainer = createElementWithAttribute('div', {
      class: 'assignment-form-container hide',
    });

    let editor
    const contents = [
      {
        elem: 'h3',
        attr: {
          class: 'assignment-form-title',
        },
        innerHTML: 'Create New Assignment'
      },
      {
        elem: 'label',
        attr: {
          for: 'assignment-name',
        },
        innerHTML: 'Assignment name'
      },
      {
        elem: 'input',
        attr: {
          type: 'text',
          name: 'assignment-name',
          class: 'form-control',
          id: 'form-input-assignment-name'
        },
      }, 
      {
        elem: 'label',
        attr: {
          for: 'assignment-description'
        },
        innerHTML: 'Description',
      }, 
      {
        elem: 'div',
        attr: {
          id: 'form-input-assignment-description'
        },
        isTextEditor: true,
      }, 
      {
        elem: 'button',
        attr: {
          id: 'assignment-form-create-btn',
          class: 'form-submit hide'
        },
        innerHTML: 'Create'
      },
      {
        elem: 'button',
        attr: {
          id: 'assignment-form-save-btn',
          class: 'form-submit hide'
        },
        innerHTML: 'Save'
      },
      {
        elem: 'button',
        attr: {
          id: 'assignment-form-cancel-btn',
          class: 'form-cancel'
        },
        innerHTML: 'Cancel'
      },
      {
        elem: 'p',
        attr: {
          id: 'assignment-form-error-message',
          class: 'assignment-form-error-message text-red hide',
        }
      }
    ]


    contents.forEach(content => {
      const {elem, attr, innerHTML, isTextEditor} = content

      const element = createElementWithAttribute(elem, attr)
      innerHTML ? element.innerHTML = innerHTML : ''

      assignmentFormContainer.appendChild(element)

      if(isTextEditor) {
        !editor ? editor = Editor(element, "") : ''
      }

      if(attr.id === 'assignment-form-create-btn') {
        element.addEventListener('click', e => {
          e.preventDefault();
        this.createOrSaveAssignment('create');
        })
      }
      
      if(attr.id === 'assignment-form-save-btn') {
        element.addEventListener('click', e => {
          e.preventDefault();
          this.createOrSaveAssignment('edit');
        })
      }

      if(attr.id === 'assignment-form-cancel-btn') {
        element.addEventListener('click', (e) => {
          const assignmentForm = document.querySelector(
            '.assignment-form-container'
          );
          //Empty form and close
          document.getElementById('form-input-assignment-name').value = '';
          document.getElementById('form-input-assignment-description').content.innerHTML = '';
          document
            .getElementById('assignment-form-error-message')
            .classList.remove('show-error-message');
          assignmentForm.classList.add('hide');

          this.currentEditAssignmentId = null;

          document
            .getElementById('assignment-form-save-btn')
            .classList.add('hide');

          document
            .getElementById('assignment-form-create-btn')
            .classList.add('hide');
        });
      }

    })

    // const assignmentTitle = createElementWithAttribute('h3', {
    //   class: 'assignment-form-title',
    // });

    // assignmentTitle.innerText = 'Create New Assignment';
    // assignmentFormContainer.appendChild(assignmentTitle);

    // const assignmentContainerName = createElementWithAttribute('label', {
    //   for: 'assignment-name',
    // })
    // assignmentFormContainer.appendChild(assignmentContainerName)

    // assignmentContainerName.innerHTML = "Assignment name";
    // const assignmentContainerNameInput = createElementWithAttribute('input', {
    //   type: 'text',
    //   name: 'assignment-name',
    //   class: 'form-control',
    //   id: 'form-input-assignment-name'
    // })
    // assignmentFormContainer.appendChild(assignmentContainerNameInput)


    // const assignmentContainerDescriptionName = createElementWithAttribute('label', {
    //   for: 'assignment-description',
    // })
    // assignmentContainerDescriptionName.innerHTML = "Description"
    // assignmentFormContainer.appendChild(assignmentContainerDescriptionName)

    // const assignmentContainerDescriptionInput = createElementWithAttribute('div', {})

    // assignmentFormContainer.appendChild(assignmentContainerDescriptionInput)





    // const assignmentForm = document.createElement('form');
    // assignmentForm.innerHTML = `<div class="form-group">
    //   <label for="assignment-name">Assignment Name</label>
    //   <input class="form-control" type="text" name="assignment-name" id="form-input-assignment-name">
    //   </div>
    //   <div class="form-group">
    //   <label for="assignment-description">Assignment Description</label>
    //   <textarea class="form-control" name="assignment-description" id="form-input-assignment-description" rows="4"></textarea>
    //   </div>
    //   <button id="assignment-form-create-btn" class="form-submit hide" type="button">Create</button>
    //   <button id="assignment-form-save-btn" class="form-submit hide" type="button">Save</button>
    //   <button id="assignment-form-cancel-btn" class="form-cancel" type="button">Cancel</button>
    //   `;

    // assignmentFormContainer.appendChild(assignmentForm);

    this.parent.appendChild(assignmentFormContainer);

    // //Form buttons event listener
    // document
    //   .getElementById('assignment-form-create-btn')
    //   .addEventListener('click', (e) => {
    //     e.preventDefault();
    //     this.createOrSaveAssignment('create');
    //   });
    // document
    //   .getElementById('assignment-form-save-btn')
    //   .addEventListener('click', (e) => {
    //     e.preventDefault();
    //     this.createOrSaveAssignment('edit');
    //   });
    // document
    //   .getElementById('assignment-form-cancel-btn')
    //   .addEventListener('click', (e) => {
    //     const assignmentForm = document.querySelector(
    //       '.assignment-form-container'
    //     );
    //     //Empty form and close
    //     document.getElementById('form-input-assignment-name').value = '';
    //     document.getElementById('form-input-assignment-description').value = '';
    //     document
    //       .getElementById('assignment-form-error-message')
    //       .classList.remove('show-error-message');
    //     assignmentForm.classList.add('hide');
    //     this.currentEditAssignmentId = null;
    //     document
    //       .getElementById('assignment-form-save-btn')
    //       .classList.add('hide');
    //     document
    //       .getElementById('assignment-form-create-btn')
    //       .classList.add('hide');
    //   });

  }

  getAssignmentList(classId) {
    //Fetch Assignment list from firebase
    getDataFromFirebase('classes/' + classId + '/assignment', this.uid)
      .then((result) => {
        if (result.status === 'OK') {
          const list = document.getElementById('assignment-list');
          list.innerHTML = '';

          this.assignmentArray = Object.entries(result.data);

          for (let [key, value] of Object.entries(result.data)) {
            const assignment = createElementWithAttribute('li', {
              class: 'list-group-item single-assignment',
              'assignment-id': key,
            });

            assignment.innerHTML = `<div class="assignment-header"><span>${
              value.name
            }</span><div><button type="button" data-toggle="collapse" data-target="#detail-${key}" aria-expanded="false" aria-controls="detail-${key}" class="outline-btn">Show detail</button><button class="edit-assignment-btn">Edit</button></div></div>  
            <div id="detail-${key}" class="collapse">

            <div class="assignment-description">
            ${value.description.replace(/\n/g, '<br />')}

            </div>
            </div>`;

            list.appendChild(assignment);
          }

          //Bind the function to each edit button
          const editButtons = document.querySelectorAll('.edit-assignment-btn');
          editButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
              const assignmentId = e.target.parentNode.parentNode.parentNode.getAttribute(
                'assignment-id'
              );
              this.editAssignment(assignmentId);
            });
          });
          //TODO: Delete later
          //Bind the function to each view student world button
          // const viewWorldButtons = document.querySelectorAll(
          //   '.view-assignment-btn'
          // );

          // viewWorldButtons.forEach((button) => {
          //   button.addEventListener('click', (e) => {
          //     const assignmentId = e.target.parentNode.parentNode.parentNode.getAttribute(
          //       'assignment-id'
          //     );
          //     this.getStudentAssignmentWorld(assignmentId);
          //   });
          // });
        } else {
          //No assignment find
          const list = document.getElementById('assignment-list');
          list.innerHTML =
            'There is no assignment yet, please use create button to create new assignment.';
        }
      })
      .catch((err) => {
        //No assignment find
        const list = document.getElementById('assignment-list');
        list.innerHTML =
          'There is no assignment yet, please use create button to create new assignment.';
      });
  }

  //Open assignment edit form
  editAssignment(assignmentId) {
    const assignmentForm = document.querySelector('.assignment-form-container');
    assignmentForm.classList.remove('hide');
    document
      .getElementById('assignment-form-save-btn')
      .classList.remove('hide');
    document
      .getElementById('assignment-form-error-message')
      .classList.remove('show-error-message');

    document.querySelector('.assignment-form-title').innerText =
      'Edit Assignment';

    //Pass assignment info to form
    const targetAssignment = this.assignmentArray.find((item) => {
      return item[0] === assignmentId;
    });

    document.getElementById('form-input-assignment-name').value = targetAssignment[1].name;
    document.getElementById('form-input-assignment-description').content.innerHTML = targetAssignment[1].description;
    this.currentEditAssignmentId = assignmentId;
    this.currentEditAssignmentModule = targetAssignment[1].assignmentModule;
  }

  //Create new Assignment
  createOrSaveAssignment(method) {
    //Get form information
    const assignmentName = document.getElementById(
      'form-input-assignment-name'
    );
    const description = document.querySelector(
      '#form-input-assignment-description .pell-content-custom-name'
    );

    let errorMsg = document.getElementById('assignment-form-error-message');
    errorMsg.innerText = '';
    errorMsg.classList.remove('show-error-message');

    if (assignmentName.value.length > 0 && description.innerHTML !== '') {
      if (method === 'edit') {
        //Update assignment information
        let updates = {};
        updates[
          '/profiles/' +
            this.uid +
            '/classes/' +
            this.currentClassId +
            '/assignment/' +
            this.currentEditAssignmentId
        ] = { name: assignmentName.value, description: description.innerHTML};

        database.ref().update(updates);

        //Empty form and close
        document.getElementById('form-input-assignment-name').value = '';
        document.getElementById('form-input-assignment-description').content.innerHTML = '';
        
        document
          .querySelector('.assignment-form-container')
          .classList.add('hide');
        errorMsg.innerText = `Successfully edit assignment.`;
        errorMsg.classList.add('show-error-message');
        errorMsg.classList.add('text-green');
        document
          .getElementById('assignment-form-save-btn')
          .classList.add('hide');

        this.getAssignmentList(this.currentClassId);
      } else {
        //Create new assignment
        let newAssignmentModuleId = shortid.generate();
        let newAssignment = database
          .ref(
            '/profiles/' +
              this.uid +
              '/classes/' +
              this.currentClassId +
              '/assignment'
          )
          .push();
        newAssignment.set(
          {
            name: assignmentName.value,
            description: description.innerHTML,
          },
          (error) => {
            if (!error) {
              //Empty form and close
              document.getElementById('form-input-assignment-name').value = '';
              document.getElementById(
                'form-input-assignment-description'
              ).value = '';
              document
                .querySelector('.assignment-form-container')
                .classList.add('hide');
              errorMsg.innerText = `Successfully created new assignment.`;
              errorMsg.classList.add('show-error-message');
              errorMsg.classList.add('text-green');
              document
                .getElementById('assignment-form-create-btn')
                .classList.add('hide');

              this.getAssignmentList(this.currentClassId);

              //Send assignment to each student
              // this.sendAssignmentToStudents(newAssignmentModuleId);
            } else {
              errorMsg.classList.remove('text-green');
              errorMsg.innerText = `Could not create new assignment.`;
              errorMsg.classList.add('show-error-message');
            }
          }
        );
      }
    } else {
      errorMsg.classList.remove('text-green');
      errorMsg.innerText = `Please input valid information.`;
      errorMsg.classList.add('show-error-message');
    }
  }

  //Assign assignment to each student under the class
  //  sendAssignmentToStudents(newAssignmentModuleId){
  //   this.getDataFromFirebase('/classes/'+this.currentClassId+'/student').then(result=>{
  //       if(result.status==="OK")
  //       {
  //         const studentIdList = result.data.map(student=>student.id);
  //         studentIdList.forEach(id=>{

  //           //Get student information from users collection
  //            database.ref('/users/' + id ).once('value').then((snapshot)=> {
  //             if(snapshot.val())
  //             {
  //               const currentData = snapshot.val();
  //               //Add assignment information to each student in class
  //               //Check exist assignments
  //               let currentAssignments = [];
  //               if(currentData.assignmentFolder)
  //               {
  //                 currentAssignments = [{assignmentModule:newAssignmentModuleId,worldId:shortid.generate()},...currentData.assignmentFolder.assignments]
  //               }
  //               else{
  //                 currentAssignments = [{assignmentModule:newAssignmentModuleId,worldId:shortid.generate()}]
  //               }

  //               database.ref('users/' + id +'/assignmentFolder').set({
  //                 classId:this.currentClassId,
  //                 path:`/profiles/${this.uid}/classes/`,
  //                 assignments: currentAssignments

  //               });
  //             }

  //           });

  //         });
  //       }
  //       else{
  //         console.log(result)
  //       }
  //   })
  //  }

  //TODO - delete latter
  getStudentAssignmentWorld(assignmentId) {
    this.removeStudentListPanel();

    //Create new panel
    const studentListPanelElement = document.createElement('ul');
    studentListPanelElement.className = 'assignment-student-list-panel';
    studentListPanelElement.innerHTML = `<button class="close-panel-btn">X</button><p class="loading-text">No submission yet</p>`;
    this.parent.appendChild(studentListPanelElement);
    document.querySelector('.close-panel-btn').addEventListener('click', () => {
      this.removeStudentListPanel();
    });

    //Get students from under teacher classes collection
    getDataFromFirebase(
      `/classes/${this.currentClassId}/student/`,
      this.uid
    ).then((result) => {
      if (result.status === 'OK') {
        for (let [key, value] of Object.entries(result.data)) {
          if (value.assignments && value.assignments[`${assignmentId}`]) {
            console.log(
              'student assignment',
              this.currentClassId,
              key,
              value.name,
              value.assignments[`${assignmentId}`].sid
            );

            const currentClass = this.currentClassId,
              currentStudentId = key,
              currentStudentName = value.name,
              currentAssignment = value.assignments[`${assignmentId}`].sid;

            studentListPanelElement.innerHTML += `<li class="assignment-link-container"><a class="assignment-link" href="${mainApp}/?origin=${classroomOrigin.origin}&class=${currentClass}&student=${currentStudentId}&name=${currentStudentName}&assignment=${currentAssignment}" class="module-link">
              ${currentStudentName}
            </a>
          
            </li>`;

            document.querySelector('.loading-text').classList.add('hide');
            document
              .querySelector('.close-panel-btn')
              .addEventListener('click', () => {
                this.removeStudentListPanel();
              });

            // studentListPanel.innerHTML=outputStudentWorldList;
          }
        }
      }
    });
  }

  removeStudentListPanel() {
    const studentListPanel = document.querySelector(
      '.assignment-student-list-panel'
    );
    if (studentListPanel) {
      this.parent.removeChild(studentListPanel);
    }
  }
}

module.exports = AssignmentView;
