// Setup firebase
var firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');
require('firebase/storage');


var config = {
  apiKey: 'AIzaSyDgLIe7Fi0eWJlDz3mzcSh6rvyefQaLgMM',
  authDomain: 'env3d-blockly-ca.firebaseapp.com',
  databaseURL: 'https://env3d-blockly-ca.firebaseio.com',
  projectId: 'env3d-blockly-ca',
  storageBucket: 'env3d-blockly-ca.appspot.com',
  messagingSenderId: '846534956161',
  appId: '1:846534956161:web:cf160c1097e7419c566980',
};
window['myLocation'] = 'https://env3d-blockly-ca.firebaseio.com';

firebase.initializeApp(config);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// make firebase available in the global namespace so index can access it
// @todo: fix this by moving all JS into src
window['firebase'] = firebase;
window['database'] = firebase.database();

// window["cloudURL"] = "http://api.myc3d.test";
window['mainApp'] = window.location.host.includes('myc3d.test')
  ? 'http://canada.myc3d.test'
  : 'https://sessionblockly.c3d.io';
window['cloudURL'] = window.location.host.includes('myc3d.test')
  ? 'http://api.myc3d.test'
  : 'https://api.c3d.io';


// origin of the sub domain -- may find better solution in the future
// window['classroomOrigin'] = require("../../origin.json");

const accounts = () => {
  let account;
  switch(window.location.hostname.split(".")[0]) {
    case "ciirve":
      account = "info@c3d.io";
      break;
    case "myschoolname":
      account = "tomoko@codeca.io";
      break;
    case "gns": 
      account = "gns@codeca.io"
      break;
    default:
      account = "tomoko@codeca.io"
      break;
  }

  return account
}

const displayNames = () => {
  let displayName;
  switch(window.location.hostname.split(".")[0]) {
    case "ciirve":
      displayName = "Ciirve";
      break;
    case "myschoolname":
      displayName = "My School Name";
      break;
    case "gns": 
      displayName = "GNS"
      break;
    default:
      displayName = "Test"
      break;
  }

  return displayName
}


window['classroomOrigin'] = {
  origin: window.location.hostname.split(".")[0],
  account: accounts(),
  displayName: displayNames(),
  isLocal: window.location.host.includes('myc3d.test') || window.location.host.includes('localhost') ? true : false
}

// console.log(window.location.hostname.split(".")[0], classroomOrigin)

// make shortid available
// @todo: fix this by moving all JS into src
//window['shortid'] = require('shortid');

// Some utility functions
window['getQueryVariable'] = function (variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

// wrap code around an async
window['asyncFunc'] = function (code, duration) {
  return new Promise(function (resolve, reject) {
    code();
    var interval = setInterval(() => {
      code();
    }, 32);
    setTimeout(() => {
      clearInterval(interval);
      resolve();
    }, duration);
  });
};

window['copyToClipboard'] = function (text) {
  if (!navigator.clipboard) {
    alert(`Please copy the link ${text} and paste it in c3d.io`);
    return;
  }
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert('text copied to clipboard');
    })
    .catch((e) => {
      console.log('error copying to clipboard', e);
    });
};

window['moduleSteps'] = {
  module1: 15,
  module2: 17,
  module3: 17,
  module4: 12,
  module5: 23,
  module6: 13,
  module7: 17,
  module8: 9,
  module9: 20,
  module10: 22,
  module11: 9,
};

window['getCookie'] = (name) => {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
};
