let AbstractComponent = require('../AbstractComponent.js');

class Welcome extends AbstractComponent {
  constructor(elem) {
    super(elem)
    this.setupElement()
  }

  setupElement() {
    const studentId = getQueryVariable('student');
    const classId = getQueryVariable('class');
    const name = getQueryVariable('name');

    if (!studentId || !name) {
      let errMsg = document.createElement('h3');
      errMsg.innerHTML = `<p>Invalid id or name, unable to get lessons.</p>
      <a href="entrance.html">Back to entrance</a>
      `;
      errMsg.className = 'error-message';
      this.parent.appendChild(errMsg);
      return;
    }

    let welcomeMsg = document.createElement('h2');
    welcomeMsg.innerHTML = `Hello, ${name}. Welcome to c3d.`;
    welcomeMsg.className = 'welcome-msg';
    this.elem.appendChild(welcomeMsg);
  }
}

module.exports = Welcome